<template>
  <div class="_bg-default pa-3">
    <v-progress-linear
      :active="loader"
      :indeterminate="loader"
      absolute
      top
      color="orange"
    ></v-progress-linear>
    <v-btn class="indigo mb-1" dark @click="helpSub = true"
      ><v-icon class="mr-1">mdi-help-circle-outline</v-icon> Bantuan</v-btn
    >
    <TutorSubmission v-bind:helpSub="helpSub" @close="close" />
    <v-row v-if="countDown <= 3">
      <v-col cols="12" md="6" v-if="fase == 'Individu'">
        <v-card
          class="mb-3"
          v-if="!$apollo.queries.tugasIndividuByStudentId.loading && config"
        >
          <v-toolbar color="indigo" dense dark
            ><h3>Tugas Individu</h3>
          </v-toolbar>
          <div
            :class="config.upload_individu == 'locked' ? 'wraper' : 'nowrap'"
          >
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-btn
                color="orange"
                dark
                @click="unlockTugasIndividu"
                :to="pathGame.unlock_tugas"
              >
                unlock
              </v-btn>
            </div>
          </div>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" md="12" v-if="!tugasIndividu">
                <div class="upload-btn-wrapper">
                  <button class="btn indigo">
                    <div class="d-flex align-center">
                      <v-icon dark size="70">mdi-file-upload</v-icon>
                      <h4>Unggah tugas Individu</h4>
                    </div>
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    @change="createTugasIndividu($event)"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="12" v-if="tugasIndividu">
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="ma-0">Nama Dokumen</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="ma-0">: {{ tugasIndividu.file }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="ma-0">Diunggah oleh</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="ma-0">: {{ tugasIndividu.user.nama_lengkap }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="ma-0">Diunggah tanggal</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="ma-0">: {{ tugasIndividu.created_at }}</p>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-btn
                    v-if="countDown > 1"
                    @click="delTugas(tugasIndividu.id)"
                    >unggah ulang</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="configKelompok">
        <v-card v-if="fase == 'Kelompok' || fase == 'in'">
          <v-toolbar color="indigo" dense dark
            ><h3>Tugas Kelompok</h3>
          </v-toolbar>
          <div
            :class="
              configKelompok.upload_grup == 'locked' ? 'wraper' : 'nowrap'
            "
          >
            <div
              class="d-flex justify-center align-center"
              style="height: 100%"
            >
              <v-btn color="orange" dark @click="unlockSubmissionGrup">
                unlock</v-btn
              >
            </div>
          </div>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" md="12" v-if="!tugasKelompok">
                <div class="upload-btn-wrapper">
                  <button class="btn indigo">
                    <div class="d-flex align-center">
                      <v-icon dark size="70">mdi-file-upload</v-icon>
                      <h4>Unggah tugas Kelompok</h4>
                    </div>
                  </button>
                  <input
                    type="file"
                    name="myfile"
                    @change="createTugasKelompok($event)"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="12" v-if="tugasKelompok">
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="ma-0">Nama Dokumen</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="ma-0">: {{ tugasKelompok.file }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="ma-0">Diunggah oleh</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="ma-0">: {{ tugasKelompok.user.nama_lengkap }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="ma-0">Diunggah tanggal</p>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="ma-0">: {{ tugasKelompok.created_at }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-btn v-if="countDown > 1">Delete</v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-card class="pa-3 ma-3 full__">
        <h2>Anda belum dapat melakukan pengumpulan tugas.</h2>
        <h2>
          Pengumpulan tugas dapat dilakukan H-3 sebelum fase pembelajaran
          selesai.
        </h2>
      </v-card>
    </v-row>
    <v-row v-if="!loader">
      <v-col cols="12" md="7">
        <v-card>
          <v-toolbar color="indigo" dense dark><h3>Nilai</h3> </v-toolbar>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">NO</th>
                  <th class="text-center">Fase</th>
                  <th class="text-center">Widyaiswara</th>
                  <th class="text-center">Coach</th>
                  <th class="text-center">Rata-rata</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td class="text-center">INDIVIDU</td>
                  <td class="text-center">
                    {{ profile.student.nilai_objektif_teacher }}
                  </td>
                  <td class="text-center">
                    {{ profile.student.nilai_objektif_mentor }}
                  </td>
                  <td class="text-center">{{ averageIndividu }}</td>
                </tr>
                <tr>
                  <td class="text-center">2</td>
                  <td class="text-center">KELOMPOK</td>
                  <td class="text-center">
                    {{ profile.grup.nilai_objektif_teacher }}
                  </td>
                  <td class="text-center">
                    {{ profile.grup.nilai_objektif_mentor }}
                  </td>
                  <td class="text-center">{{ averageKelompok }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-toolbar dense color="indigo">
            <h4 class="white--text">Contoh Format Pengumpulan Tugas</h4>
          </v-toolbar>
          <div class="pa-3">
            <a
              :href="`${env}/storage/format_laporan/Form_Analisis_Masalah_(Individu).docx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="indigo"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-word"
              >
                <span class="d-flex justify-space-between">
                  Form Analisis Masalah (Individu)
                  <v-icon color="indigo"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
            <a
              :href="`${env}/storage/format_laporan/Form_Diskusi_Masalah_(Kelompok).docx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="indigo"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-word"
              >
                <span class="d-flex justify-space-between">
                  Form Diskusi Masalah (Kelompok)
                  <v-icon color="indigo"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
            <a
              :href="`${env}/storage/format_laporan/Form_Diskusi_Pembuatan_Prototype_(Kelompok).docx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="indigo"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-word"
              >
                <span class="d-flex justify-space-between">
                  Form Diskusi Pembuatan Prototype (Kelompok)
                  <v-icon color="indigo"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
            <a
              :href="`${env}/storage/format_laporan/Form_Hasil_Akhir_Prototype_(Kelompok).docx`"
              target="_blank"
              class="textdecoration"
            >
              <v-alert
                color="indigo"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-file-word"
              >
                <span class="d-flex justify-space-between">
                  Form Hasil Akhir Prototype (Kelompok)
                  <v-icon color="indigo"> mdi-download-circle </v-icon>
                </span>
              </v-alert>
            </a>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <section></section>
    <DialogLoading v-bind:loading="loading" />
    <UnlockSubmissionGrup
      v-bind:dialogPlay="dialogPlay"
      v-bind:message="tugas_grup"
      @false="dialogFalse"
    />
    <attention-grup
      v-bind:dialogAttention="dialogAttention"
      v-bind:message="tugas_grup"
      @false="dialogFalse"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import DialogLoading from "../../components/_base/dialogLoading";
import {
  UPLOAD_TUGAS_INDIVIDU,
  UPLOAD_TUGAS_KELOMPOK,
  TUGAS_INDIVIDU_BY_STUDENT_ID,
  TUGAS_GRUP_BY_GRUPID,
  DELETE_TUGAS,
} from "../../graphql/Student";
import { mapState } from "vuex";
import { GET_NILAI_INDIVIDU } from "../../graphql/graphql";
import UnlockSubmissionGrup from "../../components/_base/PlayGrup.vue";
import AttentionGrup from "../../components/_base/attentionGrup.vue";
import TutorSubmission from "../../components/Student/tutorial/tutorSubmission.vue";

export default {
  name: "Submission",
  apollo: {
    tugasIndividuByStudentId: {
      query: TUGAS_INDIVIDU_BY_STUDENT_ID,
      variables() {
        return {
          student_id: parseInt(localStorage.getItem("id")),
        };
      },
    },
    tugasGrupByGrupId: {
      query: TUGAS_GRUP_BY_GRUPID,
      variables() {
        return {
          grup_id: this.grup_id,
        };
      },
      skip() {
        return !this.grup_id;
      },
    },
    profile: {
      query: GET_NILAI_INDIVIDU,
    },
  },
  components: {
    DialogLoading,
    UnlockSubmissionGrup,
    AttentionGrup,
    TutorSubmission,
  },
  computed: {
    ...mapState(["userProfile", "badge_tugas", "tugas_grup", "pathGame"]),
    config() {
      let data = null;
      if (this.userProfile) {
        data = JSON.parse(this.userProfile.student.config);
      }
      return data;
    },
    configKelompok() {
      let data = null;
      if (this.profile) {
        data = JSON.parse(this.profile.grup.config);
      }
      return data;
    },
    loader() {
      return this.$apollo.queries.profile.loading;
    },
    averageIndividu() {
      let hasil = null;
      if (!this.loader) {
        const teacher = this.profile.student.nilai_objektif_teacher;
        const coach = this.profile.student.nilai_objektif_mentor;
        hasil = (teacher + coach) / 2;
      }
      return hasil;
    },
    averageKelompok() {
      let hasil = null;
      if (!this.loader) {
        const teacher = this.profile.grup.nilai_objektif_teacher;
        const coach = this.profile.grup.nilai_objektif_mentor;
        hasil = (teacher + coach) / 2;
      }
      return hasil;
    },
    tugasIndividu() {
      var tugas = null;
      if (this.tugasIndividuByStudentId) {
        tugas = this.tugasIndividuByStudentId.slice(-1)[0];
      }
      return tugas;
    },
    tugasKelompok() {
      var tugas = null;
      if (this.tugasGrupByGrupId) {
        tugas = this.tugasGrupByGrupId.slice(-1)[0];
      }
      return tugas;
    },
    grup_id() {
      let id = null;
      if (this.userProfile) {
        id = parseInt(this.userProfile.grup.id);
      }
      return id;
    },
  },
  data() {
    return {
      loading: false,
      loader_grup: false,
      tugasIndividuByStudentId: [],
      env: process.env.VUE_APP_GRAPHQL,
      tugasGrupByGrupId: [],
      tugas_id: null,
      user_id: null,
      student_id: null,
      myfile: null,
      myfile_grup: null,
      keterangan: "",
      nama_lengkap: "",
      nama_lengkap_grup: "",
      profile: null,
      tugas: 1,
      dialogPlay: false,
      dialogAttention: false,
      fase: localStorage.getItem("fase"),
      countDown: localStorage.getItem("countDown"),
      helpSub: false,
    };
  },

  methods: {
    close() {
      this.helpSub = false;
    },
    unlockSubmissionGrup() {
      this.$store.dispatch("unlockTugasKelompok").then((data) => {
        console.log(data);
        let status = data.data.status;
        if (status == 1) {
          this.dialogPlay = true;
        } else if (status == 2) {
          this.dialogAttention = true;
        } else if (status == 3) {
          this.$apollo.queries.profile.refetch();
          Swal.fire({
            icon: "success",
            title: data.data.pesan,
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true,
          });
        }
      });
    },
    dialogFalse() {
      this.dialogPlay = false;
      this.dialogAttention = false;
    },
    createTugasIndividu(e) {
      this.loading = true;
      const tugas_id = this.tugas_id;
      const student_id = parseInt(localStorage.getItem("id"));
      const file = e.target.files[0];
      this.$apollo
        .mutate({
          mutation: UPLOAD_TUGAS_INDIVIDU,
          variables: {
            tugas_id: tugas_id,
            student_id: student_id,
            file: file,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          console.log(data.data);
          this.loading = false;
          this.myfile = file.name;
          this.nama_lengkap = this.userProfile.nama_lengkap;
          this.$apollo.queries.tugasIndividuByStudentId.refetch();
          Swal.fire({
            icon: "success",
            title: "Tugas Berhasil Diunggah",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          console.log(err.graphQLErrors);
          this.loading = false;
        });
    },
    delTugas(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_TUGAS,
              variables: {
                id: parseInt(id),
              },
            })
            .then(() => {
              this.$apollo.queries.tugasIndividuByStudentId.refetch();
            });
        }
      });
    },
    createTugasKelompok(e) {
      this.loading = true;
      const tugas_id = this.tugas_id;
      const user_id = parseInt(localStorage.getItem("id"));
      const grup_id = parseInt(this.userProfile.grup.id);
      const keterangan = this.keterangan;
      const file = e.target.files[0];
      this.$apollo
        .mutate({
          mutation: UPLOAD_TUGAS_KELOMPOK,
          variables: {
            user_id: user_id,
            tugas_id: tugas_id,
            grup_id: grup_id,
            keterangan: keterangan,
            file: file,
          },
          context: {
            hasUpload: true,
          },
        })
        .then((data) => {
          console.log(data);
          this.$apollo.queries.tugasGrupByGrupId.refetch();
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: "Tugas Berhasil Diunggah",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          console.log(err.graphQLErrors);
          this.loading = false;
        });
    },
    unlockTugasIndividu() {
      this.$store.dispatch("unlockTugasIndividu");
      this.$apollo.queries.tugasIndividuByStudentId.refetch();
      Swal.fire({
        imageUrl: `${process.env.VUE_APP_GRAPHQL}/badges/${this.badge_tugas.badge}`,
        imageHeight: 150,
        title: this.badge_tugas.pesan,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
      this.config.upload_individu = "unlocked";
    },
    unlockTugasKelompok() {
      this.$store.dispatch("unlockTugasKelompok");
      this.$apollo.queries.tugasGrupByGrupId.refetch();
    },
  },
};
</script>

<style scoped>
.textdecoration {
  text-decoration: none;
}
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}

/* input file custom */
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer !important;
}

.btn {
  border: none;
  color: #ffffff;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer !important;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* penghalang */
.wraper {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  transition: 0.5s;
}

.wraper:hover {
  opacity: 1;
  transition: 0.5s;
}

.nowrap {
  display: none;
  overflow: hidden;
}
.full__ {
  width: 100%;
}
</style>
