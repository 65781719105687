<template>
  <v-dialog v-model="dialogAttention" persistent max-width="430px">
    <v-card v-if="message">
      <v-card-text>
        <div class="d-flex flex-column justify-center align-center pa-3">
          <img height="200px" src="../../assets/img/gaming.svg" alt="" />
          <h3 class="text-center">{{ message.pesan }}</h3>
        </div>
        <div>
          <small>Anggota kelompok yang belum mengerjakan :</small> <br>
          <v-btn color="indigo" dark class="mr-2" small v-for="(yet, idx) in message.belum" :key="idx">{{
            yet
          }}</v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="indigo" dark @click="$emit('false')">
          Main game <v-icon dark> mdi-play </v-icon>
        </v-btn> -->
        <v-btn color="indigo" dark @click="$emit('false')"> close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogPlay",
  props: ["dialogAttention", "message"],
  methods: {},
};
</script>

<style></style>
