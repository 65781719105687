<template>
  <v-dialog v-model="dialogPlay" persistent max-width="430px">
    <v-card v-if="message">
      <v-toolbar height="30px" flat>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('false')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="d-flex flex-column justify-center align-center pa-3">
          <img height="200px" src="../../assets/img/gaming.svg" alt="" />
          <h3 class="text-center">{{ message.pesan }}</h3>
        </div>     
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="indigo" dark @click="$emit('false')">
          Main game <v-icon dark> mdi-play </v-icon>
        </v-btn> -->
        <v-btn color="indigo" dark @click="play">
          Main game <v-icon dark> mdi-play </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "playGroup",
  props: ["dialogPlay", "message"],
  methods: {
    play() {
      localStorage.setItem("from", this.$route.path);
      this.$router.push("/wiksa");

    }
  }
};
</script>

<style></style>
