<template>
  <v-row>
    <v-dialog v-model="helpSub" persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Pengumpulan tugas</h3>
        </v-toolbar>
        <div class="py-3 px-5">
          <section>
            <h3>
              Halaman ini berfungsi untuk pengumpulan tugas yang telah
              dikerjakan peserta. Peserta hanya memiliki kesempatan untuk
              mengumpulkan tugas satu kali, oleh karena itu pastikan tugas yang
              dikumpulkan sudah dikerjakan dengan benar.
            </h3>
          </section>
          <section>
            <h3>
              Khusus pengumpulan fase kelompok, pengumpulan tugas hanya
              dilakukan oleh satu dari perwakilan anggota kelompok. Untuk
              membuka pengumpulan tugas kelompok, peserta bersama rekan
              kelompoknya harus menyelesaikan permainan kolaboratif dan semua
              anggota kelompok harus memainkan permainan tersebut.
            </h3>
          </section>
          <section>
            <img
              src="../../../assets/img/tutorial/upload tugas.jpg"
              width="100%"
              alt="pic"
            />
          </section>

          <div>
            <section>
              <h3>1. Tombol untuk mengunggah tugas.</h3>
            </section>
          </div>
        </div>
        <div class="px-5">
          <section>
            <img
              src="../../../assets/img/tutorial/nilai-contoh.jpg"
              width="100%"
              alt="pic"
            />
          </section>
          <div>
            <section>
              <h3>
                2. Tampilan nilai yang diperoleh peserta setelah mengumpulkan
                tugas sesuai dengan fase. Penilaian diberikan oleh widyaiswara
                dan coach.
              </h3>
            </section>
            <section>
              <h3>
                3. Form pengerjaan tugas. Bersifat opsional peserta tidak wajib
                menggunakan form ini saat mengerjakan tugas.
              </h3>
            </section>
          </div>
          <div class="pa-5"></div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')">tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "helpSub",
  props: ["helpSub"],
  data() {
    return {
      close: false,
      counting: null,
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
